export const API_URL = "api";
export const PERSONALITY_URL =
  "https://www.16personalities.com/free-personality-test";

export const HOST_URL = "/api"; //"http://localhost:5000/api"
export const MODEL_HOST_URL = "/ml"; //"http://127.0.0.1:5001";
export const LOGOUT_URL = `${HOST_URL}/logout`; //"http://localhost:5000/logout";
export const MODEL_LOAD_URL = "/ml"; //"http://192.168.0.21:5001";

export const ADMIN_ROLE = "Admin";
export const ADVISOR_ROLE = "Advisor";
export const USER_ROLE = "User";
